<template>
  <div class="pa-5">
    <v-card class="d-flex">
      <div>
        <v-tabs v-model="tab" vertical>
          <v-tab v-for="team of unit.teams" v-bind:key="team.id">Team {{ team.id}}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="team of unit.teams" v-bind:key="team.id">
            <v-card flat>
              <v-list>
                <v-list-item v-for="membership of team.memberships" v-bind:key="membership.id">
                  {{ membership.user.firstname }} {{ membership.user.lastname }}
                </v-list-item>
              </v-list>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div>

      </div>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Settings",
  data() {
    return {
      tab: null,
      items: [
        {title: 'Teams', icon: 'mdi-help-box'},
      ],
      right: null,
    }
  },
  computed: {
    ...mapGetters('unit', {
      activeUnit: 'active',
      query: 'query'
    }),
    unit() {
      return this.query({
        collection: false,
        id: this.activeUnit?.id ?? undefined,
        relations: {
          teams: {
            store: 'team',
            relations: {
              memberships: {
                store: 'membership',
                relations: {
                  group: {
                    store: 'group'
                  },
                  user: {
                    store: 'user'
                  }
                }
              }
            }
          },
          unitType: {
            store: 'unitType'
          },
          workPackages: {
            store: 'workPackage'
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
